.parent {
    display: flex;
    flex-wrap: wrap;
  }

  .centered {
    margin: 0 auto;
  }

  .greenscreen {
    background-color: #000000;
    margin: 50px;
    border-width: 20px;
    border-color: #000000;
  }

  .toon {
    cursor: pointer;
  }

  .toon-menu {
    position: relative;
  }

  .toon-menu-small {
    margin: 10px;
    width: 64px;
  }

  .toon-menu-normal {
    margin: 10px;
    width: 96px;
  }

  .toon-portrait {
    max-width: 100%;
  }

  .border-light {
    box-shadow: 0 0 10px 2px #0370d2;
  }

  .border-neutral {
    box-shadow: 0 0 10px 2px #717273;
  }

  .border-dark {
    box-shadow: 0 0 10px 2px #c30808;
  }

  .toon-portrait-small {
    border-radius: 15px;
  }

  .toon-portrait-normal {
    border-radius: 20px;
  }

  .zeta {
    background:50% transparent url('/public/tex.skill_zeta_glow.png') no-repeat;
    background-size:contain;
    width:38px;
    height:38px;
    position:absolute;
    color:#fff;
    text-align:center;
    line-height:38px;
    font-size:12px;
    font-weight:700;
    text-shadow:1px 1px 0 #7028c9;
    left: -2%;
    top: 55%;
  }

  .zeta-small {
    width: 25px;
    font-size: 10px;
    line-height: 36px;
    top: 47%
  }

  .omicron {
    background:50% transparent url('/public/omicron-badge.png') no-repeat;
    background-size:contain;
    width:38px;
    height:38px;
    position:absolute;
    color:#fff;
    text-align:center;
    line-height:38px;
    font-size:12px;
    font-weight:700;
    text-shadow:1px 1px 0 #000;
    left: 62%;
    top: 55%;
  }

  .omicron-small {
    width: 25px;
    font-size: 10px;
    line-height: 36px;
    top: 47%
  }

  .gear {
    background-image:url('/public/relic-badge--light-side.webp');
    background-size:100%;
    background-repeat: no-repeat;
    width:47px;
    height:47px;
    position: absolute;
    left: 25%;
    top: 52%;
    color:#fff;
    text-align:center;
    line-height:45px;
    font-weight:700;
    text-shadow:1px 1px 0 #000;
    /* position:absolute;
    color:#fff;
    text-align:center;
    line-height:45px;
    font-weight:700;
    text-shadow:1px 1px 0 #000;
    left: 25%;
    top: 52%; */
  }

  .gear-small {
    width: 32px;
    font-size: 10px;
    line-height: 31px;
    top: 50%;
    /* 
    background-size: 80%; */
    /* background-position: 0 -290%; */
  }

  .gear-neutral {
    background-image:url('/public/relic-badge--neutral.webp');
    /* background-position:0 -300% */
  }

  .gear-dark {
    background-image:url('/public/relic-badge--dark-side.webp');
    /* background-position:0 -100% */
  }

  .gear-ult {
    background-image:url('/public/relic-badge--ultimate.webp');
    /* background-position:0 -200% */
  }

  .gear-req {
    background-image:url('/public/datacron-relic-requirement-badge.png');
    background-size:100%;
    width: 30px;
    height: 30px;
    position:absolute;
    color:#fff;
    text-align:center;
    line-height:30px;
    font-weight:700;
    text-shadow:1px 1px 0 #000;
    left: 35%;
    top: 75%;
  }

  .low-gear {
    /* '/public/character-level-bg-atlas.png' */
    background-image:url('/public/character-level-bg--normal.webp');
    background-size:100%;
    background-repeat: no-repeat;
    width:40px;
    height:35px;
    position:absolute;
    color:#fff;
    text-align:center;
    line-height:40px;
    font-weight:700;
    text-shadow:1px 1px 0 #000;
    left: 28%;
    top: 57%;
  }

  .low-gear-ult {
    background-image:url('/public/character-level-bg--gold.webp');
  }

  .low-gear-small {
    width: 25px;
    font-size: 10px;
    line-height: 25px;
    top: 55%;
    left: 29%;
  }

  .star {
    background:center 0 transparent url('/public/star.png') no-repeat;
    background-size:100%;
    width:30px;
    height:30px;
    position:absolute;
    color:#fff;
    text-align:center;
    line-height:32px;
    font-weight:700;
    text-shadow:1px 1px 0 #000,-1px -1px 0 #000,-1px 1px 0 #000,1px -1px 0 #000;
    left: 0%;
    top: 0%;
  }

  .caption {
    padding: 5% 0;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #3366CC;
    font-weight: bold;
  }

  .child-large {
    margin: 5px;
    height: 300px;
    width: 280px;
    padding: 15px;
  }
  
  .child-normal {
    /* flex: 1 0 10%; */
    margin: 5px;
    height: 120px;
    width: 120px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 35px;
  }

  .child-medium {
    /* flex: 1 0 10%; */
    margin: 5px;
    height: 80px;
    width: 90px;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .child-small {
    /* flex: 1 0 10%; */
    margin: 5px;
    height: 50px;
    width: 60px;
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .child-xsmall {
    /* flex: 1 0 5%; */
    margin: 5px;
    height: 40px;
    width: 50px;
    padding: 5px;
  }

  .ship-child {
    /* flex: 1 0 10%; */
    margin: 5px;
    /* height: 150px; */
    /* width: 120px; */
    padding: 5px;
  }

  .ship-squad {
    padding-bottom: 20px;
  }

  .gac-unit__bar {
    background: #555;
    border: 1px solid #ccc;
    margin-top: -1px;
    border-radius: 6px 0 6px 0;
    box-shadow: 0 0 6px rgba(0,0,0,0.5) inset;
    display: flex;
    justify-content: flex-end;
}

.gac-unit__bar-inner {
    height: 6px;
    border-radius: 6px 0 6px 0;
}

.gac-unit__bar-inner--hp {
    background-color: #16dc19;
}

.gac-unit__bar-inner--hp-low {
    background-color: #fbff44;
}

.gac-unit__bar-inner--hp-critical {
    background-color: #de0214;
}

.gac-unit__bar-inner--prot {
    background-color: #f2f2f2;
}

/* GEAR PIECES DATA */

:root {
  --color-gear-gradient-1: #4391a3;
  --color-gear-tier-1: #a5d0da;
  --color-gear-gradient-2: #4c9601;
  --color-gear-tier-2: #98fd33;
  --color-gear-gradient-4: #004b65;
  --color-gear-tier-4: #00bdfe;
  --color-gear-gradient-7: #4700a7;
  --color-gear-tier-7: #9241ff;
  --color-gear-gradient-12: #997300;
  --color-gear-tier-12: #fc3;
  --color-gear-gradient-13: #772c05;
  --color-gear-tier-13: #f56820;
  --gear-overlay: url('/public/equipment-icon-bg.c1280fb5cf8af313.webp');
  --gear-overlay-mask: url('/public/equipment-icon-bg-mask.5f96505ff9b35dbe.webp');
}

.equipment-icon {
  position: relative;
}

.equipment-icon-gear-tier-1 {
  --equipment-icon--inner-bg: radial-gradient(var(--color-gear-gradient-1),#000 80%);
  --equipment-icon__overlay-bg-color: var(--color-gear-tier-1);
}

.equipment-icon-gear-tier-2, .equipment-icon-gear-tier-3 {
  --equipment-icon--inner-bg: radial-gradient(var(--color-gear-gradient-2),#000 80%);
  --equipment-icon__overlay-bg-color: var(--color-gear-tier-2);
}

.equipment-icon-gear-tier-4, .equipment-icon-gear-tier-5, .equipment-icon-gear-tier-6 {
  --equipment-icon--inner-bg: radial-gradient(var(--color-gear-gradient-4),#000 80%);
  --equipment-icon__overlay-bg-color: var(--color-gear-tier-4);
}

.equipment-icon-gear-tier-7, .equipment-icon-gear-tier-8, .equipment-icon-gear-tier-9, .equipment-icon-gear-tier-10, .equipment-icon-gear-tier-11 {
  --equipment-icon--inner-bg: radial-gradient(var(--color-gear-gradient-7),#000 80%);
  --equipment-icon__overlay-bg-color: var(--color-gear-tier-7);
}

.equipment-icon-gear-tier-12 {
  --equipment-icon--inner-bg: radial-gradient(var(--color-gear-gradient-12),#000 80%);
  --equipment-icon__overlay-bg-color: var(--color-gear-tier-12);
}

.equipment-icon-gear-tier-13 {
  --equipment-icon--inner-bg: radial-gradient(var(--color-gear-gradient-13),#000 80%);
  --equipment-icon__overlay-bg-color: var(--color-gear-tier-13);
}

.equipment-icon__overlay {
  background: 0 0 var(--equipment-icon__overlay-bg-color) var(--gear-overlay) no-repeat;
  background-blend-mode: multiply;
  background-size: contain;
  bottom: 0;
  left: 0;
  -webkit-mask: var(--gear-overlay)no-repeat;
  mask: var(--gear-overlay) no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.equipment-icon__inner {
  aspect-ratio: 1;
  background: var(--equipment-icon--inner-bg);
  -webkit-mask: var(--gear-overlay-mask) no-repeat;
  mask: var(--gear-overlay-mask) no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.equipment-icon__img {
  display: block;
  opacity: 1;
  width: 100%;
}

.equipment-icon__mark {
  background-color: rgba(0, 0, 0, .4);
  color: #fff;
  display: block;
  font-weight: 600;
  font-size: 50%;
  height: 25%;
  padding: 0 3%;
  position: absolute;
  right: 6%;
  top: 0%;
  width: 60%;
  z-index: 10;
}

/* MOD SLICING MATERIALS DATA */

:root {
  --mod-overlay-purple: url('/public/mod-slicing--purple.a028604369f9ed7c.webp');
  --mode-overlay-gold: url('/public/mod-slicing--gold.0e9753f3f6bfac9b.webp');
  --mod-radial-gradient-purple: #4700a7;
  --mod-radial-gradient-gold: #997300;
}

.stat-mod-slicing-mat-icon {
  position: relative;
}

.stat-mod-slicing-mat-icon__img {
  display: block;
  opacity: 1;
  width: 100%;
}

.stat-mod-slicing-mat-icon--rarity-1, .stat-mod-slicing-mat-icon--rarity-2, .stat-mod-slicing-mat-icon--rarity-3, .stat-mod-slicing-mat-icon--rarity-4, .stat-mod-slicing-mat-icon--rarity-5 {
  --radial-gradient-color: var(--mod-radial-gradient-purple);
  --mod-overlay: var(--mod-overlay-purple);
}

.stat-mod-slicing-mat-icon--rarity-6, .stat-mod-slicing-mat-icon--rarity-7 {
  --radial-gradient-color: var(--mod-radial-gradient-gold);
  --mod-overlay: var(--mode-overlay-gold);
}

.stat-mod-slicing-mat-icon__inner {
  background-image: radial-gradient(var(--radial-gradient-color), #000 80%);
  border-radius: 16% 20% 0 15%;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.stat-mod-slicing-mat-icon__bg {
  background: #142d42;
  border-radius: 16% 20% 0 15%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.stat-mod-slicing-mat-icon__overlay {
  background: 0 0 var(--mod-overlay) no-repeat;
  background-blend-mode: multiply;
  background-size: contain;
  bottom: 0;
  left: 0;
  -webkit-mask: var(--mod-overlay)no-repeat;
  mask: var(--mod-overlay) no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

/* PLAYER PORTRAIT DATA */

:root {
  --vanity-kyber: url('/public/tex.vanity_portrait_league_kyber.webp');
  --vanity-aurodium: url('/public/tex.vanity_portrait_league_aurodium.webp');
  --vanity-chromium: url('/public/tex.vanity_portrait_league_chromium.webp');
  --vanity-bronzium: url('/public/tex.vanity_portrait_league_bronzium.webp');
  --vanity-carbonite: url('/public/tex.vanity_portrait_league_carbonite.webp');
  --vanity-overlay: url('/public/vanity_mask_tex.png');
}

.profile-portrait {
  position: relative;
  max-width: fit-content;
  margin: 0 auto;
}

.profile-portrait-league-kyber {
  --vanity: var(--vanity-kyber);
}
.profile-portrait-league-aurodium {
  --vanity: var(--vanity-aurodium);
}
.profile-portrait-league-chromium {
  --vanity: var(--vanity-chromium);
}
.profile-portrait-league-bronzium {
  --vanity: var(--vanity-bronzium);
}
.profile-portrait-league-carbonite {
  --vanity: var(--vanity-carbonite);
}

.profile-portrait-league {
  background:0 0 var(--vanity) no-repeat;
  background-blend-mode: multiply;
  background-size: contain;
  bottom: 0;
  left: 0;
  -webkit-mask: var(--vanity) no-repeat;
  mask: var(--vanity) no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
}

.profile-portrait-image {
  border-radius: 40px;
}

/* Misc */

.table-icon {
  width: 34px;
  height: auto;
  margin-right: 10px;
  display: inline-block;
}
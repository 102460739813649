.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.monospace {
  font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace !important;
}

.toonList {
  min-height: 200px;
}

.toonList-small {
  min-height: 145px;
}

.App-content {
  padding-bottom: 8rem;
}


.App {
  position: relative;
  padding-bottom: 60px;
  min-height: 100vh;
  background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('/public/tex.pabu_sunset.png');
  background-attachment: fixed;
}

.square-image{
  width: 100%;
  object-fit: cover; /* Required to prevent the image from stretching, use the object-position property to adjust the visible area */
  aspect-ratio: 1/1;
  background-color: #123456;
}

.banner {
  border-radius: 20px;
  height: 100%;
  object-fit: cover; /* Required to prevent the image from stretching, use the object-position property to adjust the visible area */
  aspect-ratio: 2.5/1;
}

.Footer {
  position: absolute !important;
  left: 0;
  bottom: 0;
  right: 0;
  height: 10rem;
}

.pointer {
  cursor: pointer;
}

.spaceBelow {
  margin-bottom: 1em;
}
.gacBackground {
    background-image: url('/public/gac-map.png');
    background-size:100% 100%;
    min-width: 100%;
    min-height: 600px;
    border-radius: 15px;
}

.gacBackgroundRight {
    background-size:200% 100%;
    background-position: top right;
}

.zone {
    width: 50%;
    margin: 0 auto;
}

.squadContainer {
    margin: 2%;
}

.squad {
    width: 120px;
    position: relative;
    max-width: 100%;
}

.squadImage {
    position: relative;
    width: 100%;
    max-width: 64px;
    height: auto;
    border: 2px solid grey;
}

.attackingTeam {
    position: absolute;
    left: -10px;
    bottom: -10px;
    width: 100%;
    max-width: 40px;
    border-radius: 100px;
    border: 2px solid grey;
}

.circular {
    border-radius: 100px;
}

.disabled {
    opacity: 0.5;
    filter: grayscale(1);
}

@-webkit-keyframes pulse {
    0% { box-shadow:0 0 8px gold, inset 0 0 8px gold; }
    50% { box-shadow:0 0 16px gold, inset 0 0 14px gold; }
    100% { box-shadow:0 0 8px gold, inset 0 0 8px gold; }
}

.activeTeam {
    border:2px solid gold !important;
    box-shadow: 0 0 8px gold, inset 0 0 8px gold;
    -webkit-animation: pulse 2s linear 0s infinite;
  }


.offense-button-group > * {
    margin-bottom: 0.5em !important;
}
.roteMap {
    position: relative;
    width: 100%;
    padding-bottom: 62.7%;
    background-image: url('/public/rote-map/rote-map-min.png');
    background-size: 100%;
    background-repeat: no-repeat;
}

.wrapper {
    padding: 10px;
    overflow: auto;
    max-width: 100%;
}

.planet:hover {
    transform: scale(1.1);
}

.planet {
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
}

.mustafar {
    left: 41.6%;
    top: 61%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/mustafar.png');
}

.geonosis {
    left: 37.5%;
    top: 55%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/geonosis.png');
}

.dathomir {
    left: 30%;
    top: 54.5%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/dathomir.png');
}

.haven-class-medical-station {
    left: 26%;
    top: 47.5%;
    width: 4.5%;
    padding-bottom: 4.5%;
    background-image: url('/public/rote-map/haven-class-medical-station.png');
}

.malachor {
    left: 25%;
    top: 36.5%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/malachor.png');
}

.death-star {
    left: 26.5%;
    top: 27%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/death-star.png');
}

.corellia {
    left: 48%;
    top: 56%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/corellia.png');
}

.felucia {
    left: 50%;
    top: 48%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/felucia.png');
}

.tatooine {
    left: 42.5%;
    top: 36.7%;
    width: 9%;
    padding-bottom: 7%;
    background-image: url('/public/rote-map/tatooine.png');
}

.kessel {
    left: 53.5%;
    top: 34%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/kessel.png');
}

.vandor {
    left: 50.5%;
    top: 25.5%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/vandor.png');
}

.hoth {
    left: 56%;
    top: 20%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/hoth.png');
}

.coruscant {
    left: 55%;
    top: 61%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/coruscant.png');
}

.bracca {
    left: 58%;
    top: 54%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/bracca.png');
}

.kashyyyk {
    left: 66.7%;
    top: 54%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/kashyyyk.png');
}

.lothal {
    left: 71%;
    top: 47.5%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/lothal.png');
}

.ring-of-kafrene {
    left: 72%;
    top: 37.5%;
    width: 3%;
    padding-bottom: 5%;
    background-image: url('/public/rote-map/ring-of-kafrene.png');
}

.scarif {
    left: 70%;
    top: 28%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/scarif.png');
}

.zeffo {
    left: 58%;
    top: 45%;
    width: 4%;
    padding-bottom: 4%;
    background-image: url('/public/rote-map/scarif.png');
}

.mandalore {
    left: 40%;
    top: 33%;
    width: 7%;
    padding-bottom: 5%;
    background-image: url('/public/rote-map/mandalore.png');
}

/* @-webkit-keyframes pulse {
    0% { box-shadow:0 0 8px gold, inset 0 0 8px gold; }
    50% { box-shadow:0 0 16px gold, inset 0 0 14px gold; }
    100% { box-shadow:0 0 8px gold, inset 0 0 8px gold; }
} */

.activePlanet {
    /* border-radius: 30px; */
    border:2px solid gold !important;
    box-shadow: 0 0 8px gold, inset 0 0 8px gold;
    /* -webkit-animation: pulse 2s linear 0s infinite; */
  }